<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'

export default {
  name: 'roleDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        menuIds: [],
        isAdd: true
      },
      subordinate: []
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/role/${id}`
      })

    getAction('/api/app/list').then(result => {
      this.subordinate = result.data.map(e => {
        return {
          ...e,
          name: e.appName,
          value: e.appCode
        }
      })
    })
  },
  methods: {
    getForm1() {
      return {
        title: '角色信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '角色名称',
            type: 'input',
            cols: 12,
            key: 'roleName',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '角色类型',
            type: 'input',
            cols: 12,
            key: 'roleType'
          },
          {
            name: '角色代码',
            type: 'input',
            cols: 12,
            key: 'roleCode',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '角色描述',
            type: 'input',
            cols: 12,
            key: 'roleDesc'
          }
        ]
      }
    },

    getForm2() {
      return {
        title: '角色设置',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '所属系统',
            type: 'select',
            cols: 12,
            key: 'sysCode',
            typeData: this.subordinate,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '系统内置',
            type: 'radioButton',
            cols: 12,
            key: 'sys',
            defaultValue: '0',
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ]
          },
          {
            name: '数据权限类型',
            type: 'input',
            cols: 12,
            key: 'dsType'
          },
          {
            name: '数据权限作用范围',
            type: 'input',
            cols: 12,
            key: 'dsScope'
          }
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            console.log(data)
            api.command[!this.detail.isAdd ? 'edit' : 'create']
              .call(this, {
                url: '/role',
                params: {
                  ...data
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/rights/role')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/rights/role')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1(), this.getForm2()]} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
